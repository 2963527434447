import React, { Component } from 'react'
import { Link } from "gatsby"
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/index.css'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Card from "../components/Card"
import Fade from 'react-reveal/Fade'
import { Helmet } from 'react-helmet'

class IndexPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jemma Kwak</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <Header />
        <Fade>
        <div className="Hero">
          <div className="HeroGroup">
        
            <h1>Hey! I’m Jemma, a product designer based in Los Angeles, California. I’m currently crafting experiences at <a className="figma" href="https://facebook.com">Facebook</a>. ✌️ </h1>
                  
            <div className="AboutLink">
              <Link to="/about/">More about me</Link>
              <div className="Underline"></div>
            </div>  
            
          </div>
          <div>
            <h3 className="SelectedWork">
              Selected Work
            </h3>
          </div>
          <div className="FlexColumns">
              <div className ="LeftColumn">
              <Card
                  link="/figma" 
                  image={require('../images/figma.png')}
                  title="Figma Community"
                  description="Designing for an Open Source Community"
              />
              <Card
                  link="/adobe" 
                  image={require('../images/adobe.png')}
                  title="Adobe Lightroom"
                  description="Reimagining the photography community"
              />
              </div>

              <div className = "RightColumn">
              <Card
                  link="/facebook" 
                  image={require('../images/fb.png')}
                  title="Facebook Local Services"
                  description="Creating experiences for service providers"
              />
              <Card
                  link="/bt" 
                  image={require('../images/bt.png')}
                  title="Berkeleytime"
                  description="Redesigning student course discovery"
              />
              </div>
            </div>
        </div>
        <Footer />
        </Fade>
      </div>
    )
  }
}

export default IndexPage;