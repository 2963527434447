import React, { Component } from 'react'
import Link from 'gatsby-link'
import '../css/Card.css'

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
          hovered: false
        };
      }
    
      onMouseEnter = e => {
        this.setState({ hovered: true });
      };
    
      onMouseLeave = e => {
        this.setState({ hovered: false });
      };

    render() {
        const { hovered } = this.state;
        const style = hovered ? { transform: "scale(.97)"} : {};  

        return (
            <div>
                
                <div className="ProjectCard">
                    <Link to ={this.props.link}>
                        <div style={style} className="CardImage">
                            <img src={this.props.image}/>
                        </div>
                    </Link>
                    <Link to ={this.props.link}>
                    <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} className="ProjectMeta">
                        
                            <h1 className="title">{this.props.title}</h1>
                            <h3 className="description">{this.props.description}</h3>

                    </div>
                    </Link> 
                </div>
                
             </div>
        )
    }
  }
  
  export default Card;


  